import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const threshold = 0.3;

const CollectionTitle = ({ title }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      ref={ref}
      className={`exh-collection-title${
        wasVisible ? ' reveal-left' : ' section-hide'
      }`}
    >
      <span className="slashes">{'/// '}</span>
      <span>{title}</span>
    </div>
  );
};

export default CollectionTitle;
