import React, { useMemo, useRef } from 'react';
import useWindowVerticalScroll from '../utils/useWindowVerticalScroll';

const lazyMapper = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

const ScrollBasedText = ({ text, color = 'green', direction = 'rtl' }) => {
  const multiplier = useMemo(() => (direction === 'rtl' ? -1 : 1), [direction]);
  const pageScroll = useWindowVerticalScroll();

  const textRef = useRef(null);

  return (
    <div
      className="text-scroll-wrapper"
      style={{ justifyContent: direction === 'rtl' ? 'start' : 'end' }}
    >
      {lazyMapper.map((el, index) => (
        <div
          className="text-wrapper"
          key={index}
          style={{
            color: color === 'green' ? 'var(--primary-color)' : 'white',
            transform: `translateX(${
              ((pageScroll % (textRef.current?.clientWidth * 8)) /
                (textRef.current?.clientWidth * 8)) *
              100 *
              multiplier
            }%)`,
          }}
          ref={textRef}
        >
          <span>{text}</span>
          <span className="slashes">
            {direction === 'rtl' ? ' /// ' : ' \\\\\\ '}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ScrollBasedText;
