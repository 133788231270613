import React, { useState, useEffect } from 'react';
import { lazyMapper } from './Hero';
import { useInView } from 'react-intersection-observer';

const threshold = 0.3;

const ScrollingBar = ({ text }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      ref={ref}
      className={`break-scrolling-text${wasVisible ? ' reveal' : ''}`}
    >
      {lazyMapper.map((el, index) => (
        <div className="text-to-scroll" key={index}>
          <span>{text}</span>
          <span className="slashes">{' ///'}</span>
        </div>
      ))}
    </div>
  );
};

export default ScrollingBar;
