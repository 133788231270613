import React, { useRef } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import CollectionLink from './Collection/CollectionLink';
import CollectionTitle from './Collection/CollectionTitle';
import CollectionImage from './Collection/CollectionImage';
import CollectionDescription from './Collection/CollectionDescription';
import useWindowSize from '../../utils/useWindowSize';

const Collection = ({ data }) => {
  const intl = useIntl();
  const { locale } = intl;
  const isFr = locale === 'fr';

  const { width } = useWindowSize();
  const ref = useRef(null);

  return (
    <div style={{ position: 'relative' }}>
      <div className="exh-collaction-section-wrapper">
        <div
          className="exh-collection-section"
          ref={ref}
          style={{
            top:
              width < 768
                ? `calc(100vh - ${ref.current?.clientHeight}px)`
                : '101px',
          }}
        >
          <div className="exh-collection-left-side">
            <CollectionLink
              lineOne={
                isFr && data.subtitle?.line1?.fr
                  ? data.subtitle.line1.fr
                  : data.subtitle?.line1?.en
              }
              lineTwo={
                isFr && data.subtitle?.line2?.fr
                  ? data.subtitle.line2.fr
                  : data.subtitle?.line2?.en
              }
              url={data.url}
            />
            <CollectionTitle
              title={isFr && data.titleFr ? data.titleFr : data.titleEn}
            />
            {width >= 768 ? (
              <CollectionDescription
                description={
                  isFr && data.titleFr ? data.descriptionFr : data.descriptionEn
                }
              />
            ) : null}
          </div>
          <CollectionImage source={data.photo} />
          {width < 768 ? (
            <CollectionDescription
              description={
                isFr && data.titleFr ? data.descriptionFr : data.descriptionEn
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Collection;
