import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';

const threshold = 0.3;

const CollectionLink = ({ url, lineOne, lineTwo }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div ref={ref}>
      <Link
        to={url}
        className={`exh-collection-link${
          wasVisible ? ' reveal-left' : ' section-hide'
        }`}
      >
        <span>{lineOne}</span>
        <span>{lineTwo}</span>
        <span className="slashes">{' ///'}</span>
      </Link>
    </div>
  );
};

export default CollectionLink;
