import React, { useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useWindowSize from '../../utils/useWindowSize';
import LeftArrow from '../../assets/icons/icons8-left.svg';
import RightArrow from '../../assets/icons/icons8-right.svg';

const dummyTable = [];

const NextArrow = ({ style, onClick }) => (
  <button className="hero-slider-btn next" onClick={onClick}>
    <RightArrow />
  </button>
);

const PrevArrow = ({ style, onClick }) => (
  <button className="hero-slider-btn prev" onClick={onClick}>
    <LeftArrow />
  </button>
);

const staticSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const HeroParagraphs = ({ data = dummyTable }) => {
  const intl = useIntl();
  const { locale } = intl;
  const isFr = locale === 'fr';

  const { width } = useWindowSize();

  const settings = useMemo(
    () => ({ ...staticSettings, slidesToShow: width < 768 ? 1 : 2 }),
    [width]
  );

  return (
    <div className="hero-descriptions-wrapper">
      <Slider {...settings} className="exh-hero-slider">
        {data.map((el, index) => (
          <div
            key={index}
            className="hero-paragraph"
            dangerouslySetInnerHTML={{
              __html:
                isFr && el.paragraph?.contentFr
                  ? el.paragraph.contentFr
                  : el.paragraph?.contentEn,
            }}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HeroParagraphs;
