import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import LayoutComponent from '../components/Layout';
import Seo from '../components/Seo';
import { useStaticQuery, graphql } from 'gatsby';
import Hero from '../components/exhibition/Hero';
import ScrollingBar from '../components/exhibition/ScrollingBar';
import Collection from '../components/exhibition/Collection';
import VideoSection from '../components/exhibition/Video';
import Gallery from '../components/exhibition/Gallery';
import BrandsSection from '../components/exhibition/Brands';
import LogosSection from '../components/exhibition/Logos';

const ExhibitionPage = () => {
  const data = useStaticQuery(graphql`
    query ExhibitionQuery {
      allWpExhibition {
        edges {
          node {
            id
            exhibition {
              breakScrollingText
              brandsSection {
                descriptionEn
                descriptionFr
                sectionTitleEn
                sectionTitleFr
                transitionTextEn
                transitionTextFr
                brands {
                  brand {
                    descriptionEn
                    descriptionFr
                    name
                    photo {
                      sourceUrl
                      localFile {
                        url
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED, width: 960)
                        }
                      }
                    }
                  }
                }
              }
              collaboratorsSection {
                logos {
                  sourceUrl
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 960)
                    }
                  }
                }
                transitionTextEn
                transitionTextFr
              }
              collectionSection {
                descriptionEn
                descriptionFr
                photo {
                  sourceUrl
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 960)
                    }
                  }
                }
                titleEn
                titleFr
                subtitle {
                  line1 {
                    en
                    fr
                  }
                  line2 {
                    en
                    fr
                  }
                  url
                }
              }
              gallerySection {
                callToActionFr
                callToActionLabelEn
                sectionTitleEn
                sectionTitleFr
                transitionTextEn
                transitionTextFr
                looks {
                  look {
                    candidateName
                    descriptionEn
                    descriptionFr
                    url
                    photos {
                      sourceUrl
                      localFile {
                        url
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED, width: 960)
                        }
                      }
                    }
                  }
                }
              }
              heroSection {
                descriptions {
                  paragraph {
                    contentEn
                    contentFr
                  }
                }
                photo {
                  sourceUrl
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 960)
                    }
                  }
                }
                subtitleEn
                subtitleFr
                titleEn
                titleFr
              }
              partnersSection {
                logos {
                  sourceUrl
                  localFile {
                    url
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 960)
                    }
                  }
                }
                transitionTextEn
                transitionTextFr
              }
              videoSection {
                show
                transtionText1 {
                  en
                  fr
                }
                transtionText2 {
                  en
                  fr
                }
                video {
                  mediaItemUrl
                }
              }
            }
            featuredImage {
              node {
                sourceUrl
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;
  const isFr = locale === 'fr';
  const exhibitionData =
    data?.allWpExhibition?.edges?.length > 0
      ? data?.allWpExhibition?.edges[0].node?.exhibition
      : null;

  return (
    <>
      <LayoutComponent noPadding>
        {exhibitionData ? (
          <>
            <Seo
              title={intl.formatMessage({ id: 'exhibition.title' })}
              thumbnail={
                data?.allWpExhibition?.edges[0].node?.featuredImage?.node
                  ?.localFile?.url
              }
            />
            <div className="exhibition-section">
              <Hero data={exhibitionData.heroSection} />
              <ScrollingBar text={exhibitionData.breakScrollingText} />
              <Collection data={exhibitionData.collectionSection} />
              {exhibitionData.videoSection?.show ? (
                <VideoSection
                  videoURL={exhibitionData.videoSection?.video?.mediaItemUrl}
                  lineOne={
                    isFr && exhibitionData.videoSection?.transtionText1?.fr
                      ? exhibitionData.videoSection.transtionText1.fr
                      : exhibitionData.videoSection?.transtionText1?.en
                  }
                  lineTwo={
                    isFr && exhibitionData.videoSection?.transtionText2?.fr
                      ? exhibitionData.videoSection.transtionText2.fr
                      : exhibitionData.videoSection?.transtionText2?.en
                  }
                />
              ) : null}
              <Gallery data={exhibitionData.gallerySection} isFr={isFr} />
              <BrandsSection data={exhibitionData.brandsSection} isFr={isFr} />
              <LogosSection data={exhibitionData.partnersSection} isFr={isFr} />
              <LogosSection
                data={exhibitionData.collaboratorsSection}
                isFr={isFr}
              />
            </div>
          </>
        ) : null}
      </LayoutComponent>
    </>
  );
};

export default ExhibitionPage;
