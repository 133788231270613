import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'gatsby-plugin-react-intl';
import useWindowSize from '../../../utils/useWindowSize';
import ScrollBasedText from '../../ScrollBasedText';
import MuteIcon from '../../../assets/icons/icons8-no-audio.svg';
import UnMuteIcon from '../../../assets/icons/icons8-audio.svg';
import Fullscreen from '../../../assets/icons/icons8-full-screen.svg';
import useFullScreen from '../../../utils/isFullScreen';

const threshold = 0.1;

const createTable = (n = 0) => {
  const result = [];
  for (let index = 0; index < n; index++) {
    result.push(index + 1);
  }

  return result;
};

const VideoSection = ({ videoURL, lineOne, lineTwo }) => {
  const { ref: triggerRef, inView: triggerInView } = useInView({
    threshold,
  });

  const [transitionClass, setTransitionClass] = useState('hidden');

  const { height, width } = useWindowSize();

  const isFullScreen = useFullScreen();

  useEffect(() => {
    if (triggerInView && transitionClass !== 'shown') {
      setTransitionClass('shown');
    } else if (!triggerInView && transitionClass === 'shown') {
      setTransitionClass('hidden');
    }
  }, [transitionClass, triggerInView]);

  const mapper = useMemo(
    () =>
      createTable(
        width < 768 ? (height - 101) / 48 / 2 : (height - 101) / 77 / 2
      ),
    [height, width]
  );

  const videoRef = useRef(null);
  const intl = useIntl();

  const [videoPaused, setVideoPaused] = useState(false);
  const [initPause, setInitPause] = useState(false);
  const [mute, setMute] = useState(true);

  useEffect(() => {
    if (!initPause && width && width < 768) {
      setVideoPaused(true);
      setInitPause(true);
    }
  }, [initPause, width]);

  const toggleMuteState = useCallback(() => {
    setMute(old => !old);
  }, []);

  const goFullScreen = useCallback(() => {
    const elem = videoRef?.current;
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }, []);

  return (
    <>
      <div className={`exh-transition-section ${transitionClass}`}>
        {mapper.map((el, index) => (
          <div key={index}>
            <ScrollBasedText
              text={lineOne}
              color={
                index !== Math.floor(mapper.length / 2) ? 'white' : 'green'
              }
            />
            <ScrollBasedText
              text={lineTwo}
              color={
                index !== Math.floor(mapper.length / 2) ? 'white' : 'green'
              }
              direction="ltr"
            />
          </div>
        ))}
      </div>
      <div className="exh-video-section">
        <div className="exh-transition-trigger" ref={triggerRef} />
        <div className="exh-content-track">
          <div className="content-wrapper">
            {videoURL ? (
              <div className="exh-video-wrapper">
                <div className="exh-video-loading-indicator">
                  <div style={{ paddingBottom: 70 }}>
                    {intl.formatMessage({ id: 'exhibition.loadingVideo' })}
                  </div>
                </div>
                <video
                  className="exh-video-full"
                  loop
                  autoPlay={width >= 768}
                  muted={mute}
                  ref={videoRef}
                  controls={isFullScreen}
                >
                  <source src={videoURL} type="video/mp4" />
                </video>
                <div
                  className="exh-video-overlay"
                  onClick={() => {
                    if (videoRef.current?.paused) {
                      videoRef.current.play();
                      setVideoPaused(false);
                    } else {
                      videoRef.current.pause();
                      setVideoPaused(true);
                    }
                  }}
                >
                  <div
                    className={`exh-video-pause-state${
                      videoPaused ? ' display' : ''
                    }`}
                  >
                    {intl.formatMessage({ id: 'exhibition.paused' })}
                  </div>
                </div>
                <div className="exh-video-controls">
                  <button
                    className="exh-video-fullscreen-control"
                    onClick={goFullScreen}
                  >
                    <Fullscreen />
                  </button>
                  <button
                    className="exh-video-audio-control"
                    onClick={toggleMuteState}
                  >
                    {mute ? <MuteIcon /> : <UnMuteIcon />}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSection;
