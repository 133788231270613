import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ScrollBasedText from '../../ScrollBasedText';

const threshold = 0.1;

const LogosSection = ({ data, isFr }) => {
  const { ref: triggerRef, inView: triggerInView } = useInView({
    threshold,
  });

  const [transitionClass, setTransitionClass] = useState('hidden');

  useEffect(() => {
    if (triggerInView && transitionClass !== 'shown') {
      setTransitionClass('shown');
    } else if (!triggerInView && transitionClass === 'shown') {
      setTransitionClass('hidden');
    }
  }, [transitionClass, triggerInView]);

  return (
    <>
      <div className={`exh-transition-section ${transitionClass}`}>
        <ScrollBasedText
          text={
            isFr && data.transitionTextFr
              ? data.transitionTextFr
              : data.transitionTextEn
          }
        />
      </div>
      <div className="exh-logos-section">
        <div className="exh-transition-trigger" ref={triggerRef} />
        <div className="exh-logos-wrapper">
          {data?.logos.map(el => (
            <img key={el.sourceUrl} src={el.sourceUrl} alt="logo-placement" />
          ))}
        </div>
      </div>
    </>
  );
};

export default LogosSection;
