import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const threshold = 0.3;

const CollectionImage = ({ source }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      className={`section-photo-wrapper${
        wasVisible ? ' reveal-right' : ' section-hide'
      }`}
      ref={ref}
      style={{
        backgroundImage: `url(${source?.localFile?.childImageSharp?.gatsbyImageData?.placeholder?.fallback})`,
      }}
    >
      <img
        className="section-photo"
        src={source?.localFile?.url}
        alt="exhbition placeholder"
      />
    </div>
  );
};

export default CollectionImage;
