import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const threshold = 0.3;

const CollectionDescription = ({ description }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      ref={ref}
      className={`exh-collection-description${
        wasVisible ? ' reveal-right' : ' section-hide'
      }`}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default CollectionDescription;
