import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import HeroParagraphs from './HeroParagraphs';
import useWindowSize from '../../utils/useWindowSize';

export const lazyMapper = [
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
];

const Hero = ({ data }) => {
  const intl = useIntl();
  const { locale } = intl;
  const isFr = locale === 'fr';

  const { width } = useWindowSize();

  return data ? (
    <div className="exh-hero-section">
      <div className="exh-hero-right">
        <div>
          <div className="small-scrolling-text">
            {lazyMapper.map((el, index) => (
              <div className="text-to-scroll" key={index}>
                <span>
                  {isFr && data?.subtitleFr
                    ? data.subtitleFr
                    : data?.subtitleEn}
                </span>
                <span>{' /// '}</span>
              </div>
            ))}
          </div>
          <div className="section-title">
            <span>{isFr && data?.titleFr ? data.titleFr : data?.titleEn}</span>
            <span className="slashes">{' ///'}</span>
          </div>
        </div>
        {width >= 768 && data.descriptions.length > 0 ? (
          <HeroParagraphs data={data.descriptions} />
        ) : null}
      </div>
      <div
        className="section-photo-wrapper"
        style={{
          backgroundImage: `url(${data.photo?.localFile?.childImageSharp?.gatsbyImageData?.placeholder?.fallback})`,
        }}
      >
        <img
          className="section-photo"
          src={data.photo?.localFile.url}
          alt=" "
        />
      </div>
      {width < 768 && data.descriptions.length > 0 ? (
        <HeroParagraphs data={data.descriptions} />
      ) : null}
    </div>
  ) : null;
};

export default Hero;
