import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import ScrollBasedText from '../../ScrollBasedText';
import LeftArrow from '../../../assets/icons/icons8-left.svg';
import RightArrow from '../../../assets/icons/icons8-right.svg';
import useWindowSize from '../../../utils/useWindowSize';

const threshold = 0.1;

const NextArrow = ({ style, onClick }) => (
  <button className="exh-gallery-slider-btn next" onClick={onClick}>
    <RightArrow />
  </button>
);

const PrevArrow = ({ style, onClick }) => (
  <button className="exh-gallery-slider-btn prev" onClick={onClick}>
    <LeftArrow />
  </button>
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const countPhotos = (looks = []) => {
  let count = 0;
  looks.forEach(el => {
    if (el?.look?.photos) {
      count += el.look.photos.length;
    }
  });

  return count;
};

const Gallery = ({ data, isFr }) => {
  const { ref: triggerRef, inView: triggerInView } = useInView({
    threshold,
  });

  const [transitionClass, setTransitionClass] = useState('hidden');

  useEffect(() => {
    if (triggerInView && transitionClass !== 'shown') {
      setTransitionClass('shown');
    } else if (!triggerInView && transitionClass === 'shown') {
      setTransitionClass('hidden');
    }
  }, [transitionClass, triggerInView]);

  const { width } = useWindowSize();

  return (
    <>
      <div className={`exh-transition-section ${transitionClass}`}>
        <ScrollBasedText
          text={
            isFr && data.transitionTextFr
              ? data.transitionTextFr
              : data.transitionTextEn
          }
          color="green"
        />
      </div>
      <div className="exh-gallery-section">
        <div className="exh-transition-trigger" ref={triggerRef} />
        <div
          className="exh-content-track"
          style={{
            height: `${
              width < 768
                ? 225 * data.looks.length
                : 100 * countPhotos(data.looks)
            }vh`,
            borderTop: width < 768 ? undefined : '1px solid white',
          }}
        >
          <div className="exh-gallery-title">
            <span>
              {isFr && data.sectionTitleFr
                ? data.sectionTitleFr
                : data.sectionTitleEn}
            </span>
            <span>{' ///'}</span>
          </div>
          {width < 768
            ? data.looks.map((el, index) => (
                <div className="exh-gallery-slide" key={index}>
                  <div className="exh-gallery-slide-content">
                    <div className="exh-gallery-candidate-name">
                      <span>{el.look.candidateName}</span>
                      <span>{' ///'}</span>
                    </div>
                    <div className="exh-carousel-wrapper">
                      <Slider {...settings}>
                        {el.look.photos.map((photo, index) => (
                          <div key={index}>
                            <div
                              className="exh-carousel-photo-wrapper"
                              style={{
                                backgroundImage: `url(${photo?.localFile?.childImageSharp?.gatsbyImageData?.placeholder?.fallback})`,
                              }}
                            >
                              <div className="exh-carousel-blur-bg" />
                              <img
                                src={photo?.localFile.url}
                                alt=""
                                className="exh-carousel-photo"
                              />
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                    <div
                      className="exh-gallery-desc"
                      dangerouslySetInnerHTML={{
                        __html:
                          isFr && el?.look?.descriptionFr
                            ? el.look.descriptionFr
                            : el?.look?.descriptionEn,
                      }}
                    />
                    <Link to={el.look.url} className="exh-gallery-cta">
                      <span>
                        {isFr && data.callToActionFr
                          ? data.callToActionFr
                          : data.callToActionLabelEn}
                      </span>
                      <span>{' >>>'}</span>
                    </Link>
                  </div>
                </div>
              ))
            : data.looks.map((el, index) => (
                <div
                  key={index}
                  className="exh-look-display-wrapper"
                  style={{
                    height: `calc(${100 * el.look.photos.length}vh - ${
                      101 * el.look.photos.length
                    }px)`,
                  }}
                >
                  <div className="exh-look-photos-wrapper">
                    {el.look.photos.map(photo => (
                      <div
                        key={photo?.localFile.url}
                        style={{
                          backgroundImage: `url(${photo?.localFile?.childImageSharp?.gatsbyImageData?.placeholder?.fallback})`,
                        }}
                        className="exh-look-photo-wrapper"
                      >
                        <div className="exh-carousel-blur-bg" />
                        <img
                          src={photo?.localFile.url}
                          alt="look placement"
                          className="exh-look-photo"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="exh-look-informatio-track">
                    <div className="exh-look-information">
                      <div className="exh-gallery-candidate-name">
                        <span>{el.look.candidateName}</span>
                        <span>{' ///'}</span>
                      </div>
                      <div
                        className="exh-gallery-desc"
                        dangerouslySetInnerHTML={{
                          __html:
                            isFr && el?.look?.descriptionFr
                              ? el.look.descriptionFr
                              : el?.look?.descriptionEn,
                        }}
                      />
                      <Link to={el.look.url} className="exh-gallery-cta">
                        <span>
                          {isFr && data.callToActionFr
                            ? data.callToActionFr
                            : data.callToActionLabelEn}
                        </span>
                        <span>{' >>>'}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
