import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import ScrollBasedText from '../../ScrollBasedText';

const threshold = 0.1;

const BrandsSection = ({ data, isFr, brands = [] }) => {
  const { ref: triggerRef, inView: triggerInView } = useInView({
    threshold,
  });

  const [transitionClass, setTransitionClass] = useState('hidden');

  useEffect(() => {
    if (triggerInView && transitionClass !== 'shown') {
      setTransitionClass('shown');
    } else if (!triggerInView && transitionClass === 'shown') {
      setTransitionClass('hidden');
    }
  }, [transitionClass, triggerInView]);

  return (
    <>
      <div className={`exh-transition-section ${transitionClass}`}>
        <ScrollBasedText
          text={
            isFr && data.transitionTextFr
              ? data.transitionTextFr
              : data.transitionTextEn
          }
        />
      </div>
      <div className="exh-brands-section">
        <div className="exh-transition-trigger" ref={triggerRef} />
        <div className="exh-brands-wrapper">
          <div className="exh-brands-content">
            <div className="exh-brands-title">
              <span>
                {isFr && data.sectionTitleFr
                  ? data.sectionTitleFr
                  : data.sectionTitleEn}
              </span>
              <span>{' ///'}</span>
            </div>
            <div className="exh-brands-description">
              {isFr && data.descriptionFr
                ? data.descriptionFr
                : data.descriptionEn}
            </div>
            <div className="exh-brands-mapper">
              {data.brands.map(el => (
                <div key={el.brand?.name} className="exh-brand-wrapper">
                  <div
                    className="exh-brand-photo"
                    style={{
                      backgroundImage: `url(${el.brand?.photo?.sourceUrl})`,
                    }}
                  />
                  <div className="exh-brand-name">
                    <span>{el.brand?.name}</span>
                    <span>{' ///'}</span>
                  </div>
                  <div
                    className="exh-brand-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        isFr && el.brand?.descriptionFr
                          ? el.brand.descriptionFr
                          : el?.brand?.descriptionEn,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandsSection;
