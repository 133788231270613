/* eslint-disable no-restricted-globals*/

import { useEffect, useState } from 'react';

const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreen = () => {
      setIsFullScreen(document.fullscreenElement?.nodeName === 'VIDEO');
    };

    window.addEventListener('fullscreenchange', handleFullScreen);

    handleFullScreen();

    return window.removeEventListener('fullscreenchange', handleFullScreen);
  });

  return isFullScreen;
};

export default useFullScreen;
